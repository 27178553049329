<template>
  <b-container>
    <b-alert variant="danger" :show="showError">
      <span class="circle-error">!</span>
      <span>{{ errorMessage }}</span>
    </b-alert>
    <div v-show="loaded">
      <form id="paymentForm">
        <b-row>
          <b-col md="7">
            <p>
              Please review the amounts due, enter your name and click 'Pay
              Now'. You may wish to make additional payments. You can do this by
              clicking 'I want to pay another amount'.
            </p>
          </b-col>
        </b-row>
        <b-form-row v-show="bond > 0" id="bond-row" class="mb-2">
          <b-col cols="5" sm="3" md="2" class="pt-1">
            <label class="form-label" for="bond-paid">Bond:</label>
          </b-col>
          <b-col cols="6" sm="2" lg="1" class="pt-1">
            <span> ${{ bond }}</span>
          </b-col>
          <b-col v-show="isAnotherAmount" cols="10" sm="5" md="2">
            <b-form-input
              class="form-control"
              type="number"
              name="paidBond"
              @keydown.69.stop.prevent=""
              v-model="$v.bondPaid.$model"
            />
            <div
              class="validator_wrap"
              v-if="$v.bondPaid.$dirty && !$v.bondPaid.between"
            >
              <div class="error" v-if="!$v.bondPaid.between">
                * Value must be from 0 to 9999
              </div>
            </div>
          </b-col>
          <b-col cols="1" class="pt-1">
            <span id="bondInfo" class="info">?</span>
            <b-tooltip target="bondInfo" placement="right" triggers="hover">
              This is the outstanding balance of your rental bond. Payments
              should be made in accordance with your bond payment plan agreement
              signed at the start of your tenancy.
            </b-tooltip>
          </b-col>
        </b-form-row>
        <b-form-row id="rent-row" class="mb-2">
          <b-col cols="5" sm="3" md="2" class="pt-1">
            <label class="form-label" for="rent-paid">Rent:</label>
          </b-col>
          <b-col cols="6" sm="2" lg="1" class="pt-1">
            <span> ${{ rent }}</span>
          </b-col>
          <b-col v-show="isAnotherAmount" cols="10" sm="5" md="2">
            <b-form-input
              class="form-control"
              type="number"
              name="paidRent"
              @keydown.69.stop.prevent=""
              v-model="$v.rentPaid.$model"
            />
            <div
              class="validator_wrap"
              v-if="$v.rentPaid.$dirty && !$v.rentPaid.between"
            >
              <div class="error" v-if="!$v.rentPaid.between">
                * Value must be from 0 to 9999
              </div>
            </div>
          </b-col>
          <b-col cols="1" class="pt-1">
            <span id="rentInfo" class="info">?</span>
            <b-tooltip target="rentInfo" placement="right" triggers="hover">
              The rental amount shown will pay your rent 14 days in advance and
              is based on your current weekly rent amount. It does not consider
              any rent changes because of a rent review.
            </b-tooltip>
          </b-col>
          <b-col cols="2" sm="1" class="pt-1">
            <span style="white-space: nowrap">
              Up to {{ rentUpToFormatted }}
            </span>
          </b-col>
        </b-form-row>
        <b-form-row id="water-row" class="mb-2">
          <b-col cols="5" sm="3" md="2" class="pt-1">
            <label class="form-label" for="water-paid">Water usage:</label>
          </b-col>
          <b-col cols="6" sm="2" lg="1" class="pt-1">
            <span> ${{ water }}</span>
          </b-col>
          <b-col v-show="isAnotherAmount" cols="10" sm="5" md="2">
            <b-form-input
              class="form-control"
              type="number"
              name="paidWater"
              @keydown.69.stop.prevent=""
              v-model="$v.waterPaid.$model"
            />
            <div
              class="validator_wrap"
              v-if="$v.waterPaid.$dirty && !$v.waterPaid.between"
            >
              <div class="error" v-if="!$v.waterPaid.between">
                * Value must be from 0 to 9999
              </div>
            </div>
          </b-col>
          <b-col cols="1" class="pt-1">
            <span id="waterInfo" class="info">?</span>
            <b-tooltip target="waterInfo" placement="right" triggers="hover">
              This is the amount due for water today. The balance should be paid
              within 28 days of the bill issue date. If you have a shared water
              meter you should include an additional 2 weeks water charges to
              ensure your account remains paid up to date.
            </b-tooltip>
          </b-col>
        </b-form-row>
        <b-form-row id="maintenance-row" class="mb-2">
          <b-col cols="5" sm="3" md="2" class="pt-1">
            <label class="form-label" for="maintenance-paid"
              >Maintenance:</label
            >
          </b-col>
          <b-col cols="6" sm="2" lg="1" class="pt-1">
            <span> ${{ maintenance }}</span>
          </b-col>
          <b-col v-show="isAnotherAmount" cols="10" sm="5" md="2">
            <b-form-input
              class="form-control"
              type="number"
              name="paidMaintenance"
              @keydown.69.stop.prevent=""
              v-model="$v.maintenancePaid.$model"
            />
            <div
              class="validator_wrap"
              v-if="$v.maintenancePaid.$dirty && !$v.maintenancePaid.between"
            >
              <div class="error" v-if="!$v.maintenancePaid.between">
                * Value must be from 0 to 9999
              </div>
            </div>
          </b-col>
          <b-col cols="1" class="pt-1">
            <span id="maintenanceInfo" class="info">?</span>
          </b-col>
          <b-tooltip
            target="maintenanceInfo"
            placement="right"
            triggers="hover"
          >
            This is the outstanding balance for repairs and damages that have
            been invoiced as a customer recharge. This should be paid within 28
            days of the invoice issue date, or in accordance with an agreed
            payment plan.
          </b-tooltip>
        </b-form-row>
        <b-row id="separator-row" class="mb-4 mt-4">
          <div class="col-md-5 separator"></div>
        </b-row>
        <b-row id="total-row" class="mb-2">
          <b-col md="2" sm="3" cols="5">
            <label class="form-label">Total payable:</label>
          </b-col>
          <b-col cols="6" sm="2">
            <span class="total">${{ totalPaid.toFixed(2) }}</span>
          </b-col>
          <b-col class="form-check">
            <input
              id="partial-check"
              class="form-check-input"
              type="checkbox"
              v-model="isAnotherAmount"
            />
            <label class="form-check-label" for="partial-check"
              >I want to pay another amount</label
            >
          </b-col>
        </b-row>
        <b-row id="payer-row" class="mb-2">
          <b-col cols="6" sm="3" md="2" class="pt-1">
            <label class="form-label required" for="payer-name">
              Payer name:
            </label>
          </b-col>
          <b-col cols="12" sm="9" md="5">
            <input
              class="form-control"
              type="text"
              name="payer"
              v-model.trim="payerName"
            />
          </b-col>
        </b-row>
        <div v-show="!isNaN(totalPaid) && totalPaid >= 1 && !$v.$anyError">
          <img
            class="payment-methods"
            src="@/assets/payment-methods.png"
            alt="Payment methods accepted are Mastercard and Visa cards"
          />
          <div ref="eWayPlace"></div>
        </div>
      </form>
      <div id="footnote" class="footnote col-md-7">
        <p>Note:</p>
        <ul>
          <li>
            The rent due includes the amount required to pay your rent 14 days
            in advance in accordance with the Residential Tenancies Act.
          </li>
          <li>
            Rent due and paid up to date is based on current weekly rent and
            does not consider any rent changes because of a rent review.
          </li>
          <li>
            If you have a shared water meter you should include an additional 2
            weeks water charges to ensure your account remains paid up to date.
          </li>
          <li>
            Amounts due may not reflect payments made within last 3 business
            days.
          </li>
        </ul>
      </div>
    </div>
    <div class="text-center mt-5 mb-5" v-show="!loaded && !showError">
      <b-spinner label="Loading..."></b-spinner>
      <p id="cancel-label">
        We are looking up your payment information, please wait...
      </p>
    </div>
  </b-container>
</template>
<script>
import axios from 'axios';
import { between, decimal } from 'vuelidate/lib/validators';

function addDays(date, days) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export default {
  name: 'payments',
  data() {
    return {
      errorMessage: null,
      showError: false,
      loaded: false,
      message: null,
      total: null,
      totalPaid: 0,
      waterPaid: 0,
      bondPaid: 0,
      maintenancePaid: 0,
      rentPaid: 0,
      payerName: '',
      water: 0,
      bond: 0,
      maintenance: 0,
      rent: 0,
      weeklyRent: 0,
      amountInHand: 0,
      rentUpTo: null,
      rentUpToCalculated: null,
      rentUpToFormatted: '',
      isAnotherAmount: false,
      sessionLeftTime: 0
    };
  },
  props: {
    tenantCode: String,
    errorCode: String,
    processError: String,
  },
  validations: {
    waterPaid: {
      decimal,
      between: between(0, 9999),
    },
    bondPaid: {
      decimal,
      between: between(0, 9999),
    },
    rentPaid: {
      decimal,
      between: between(0, 9999),
    },
    maintenancePaid: {
      decimal,
      between: between(0, 9999),
    },
  },
  mounted() {
    if (!localStorage.userLoggedIn) {
      return;
    }

    // Save the tenant code received from the search page; restore it if the page reloaded
    if(this.tenantCode)
      localStorage.tenantCode = this.tenantCode;
    else 
      this.tenantCode = localStorage.tenantCode;

    axios
      .get(`/api/balances?code=${process.env.VUE_APP_API_KEY_BALANCES}`)
      .then((response) => {
        // if redirected back from process
        if (this.processError) {
          this.errorMessage = this.processError;
          this.showError = true;
          if (this.errorCode === 401) {            
              this.showError = false;
              this.$router.push({
                  name: 'search',
                  params: {               
                    processError: this.errorMessage,
                },
              });            
          }
        }

        this.response = response.data;
        const balancesObj = JSON.parse(JSON.stringify(this.response));
        this.setTimer(balancesObj);
        this.initBalances(balancesObj);
        this.storePaidDefaults(true);
        this.addEwayScript();
        this.loaded = this.errorCode !== 401;
      })
      .catch((error) => {
        this.showError = true;
        if (error.response.status === 404) {
          this.errorMessage = 'Payment information is not found.';
        } else if (error.response.status === 400) {
          this.errorMessage =
            'Your query can not be processed. Invalid or missing parameters.';
        } else if (error.response.status === 401) {
          this.errorMessage = 'Unauthorized request. Your session is expired.';          
            this.showError = false;
            this.closePaymentWindow();
            this.$router.push({
                  name: 'search',
                  params: {               
                    processError: this.errorMessage,
                },
              });
          
        } else if (error.response.status === 422) {
          this.$router.push({
            name: 'receipt',
          });
        } else {
          this.errorMessage = 'Your query can not be processed. Server error.';
        }
      });
  },
  methods: {
    closePaymentWindow(){
      if(document.getElementById("eway-payment-window") != null)
              {
                const element = document.getElementById("eway-payment-window");
                element.remove();
              }   
    },
    setTimer(balancesObj){      
      setTimeout(() => { 
                this.errorMessage = 'Unauthorized request. Your session is expired.';
                this.closePaymentWindow();
                this.$router.push({
                  name: 'search',
                  params: {               
                    processError: this.errorMessage,
                },
              });
            }, balancesObj.sessionLeftTime);

    },
    initBalances(balancesObj) {
      if (balancesObj.water < 0) {
        this.water = parseFloat(0);
      } else {
        this.water = balancesObj.water;
      }

      if (balancesObj.maintenance < 0) {
        this.maintenance = parseFloat(0);
      } else {
        this.maintenance = balancesObj.maintenance;
      }

      if (balancesObj.rent < 0) {
        this.rent = parseFloat(0);
      } else {
        this.rent = balancesObj.rent;
      }

      if (balancesObj.bond < 0) {
        this.bond = parseFloat(0);
      } else {
        this.bond = balancesObj.bond;
      }
      const now = new Date();
      const tcyStartDate = new Date(balancesObj.tenancyStartDate);
      this.rentUpTo =
        this.rent > 0
          ? addDays(tcyStartDate > now ? tcyStartDate : now, 13)
          : addDays(new Date(balancesObj.rentUpTo), 0);
      this.rentUpToCalculated = this.rentUpTo;
      this.rentUpToFormatted =
        this.rentUpToCalculated.toLocaleDateString('en-AU');
      this.amountInHand = balancesObj.amountInHand;
      this.weeklyRent = balancesObj.weeklyRent;

      this.waterPaid = this.water;
      this.bondPaid = this.bond;
      this.maintenancePaid = this.maintenance;
      this.rentPaid = this.rent;
    },
    isEWayWindowLoading(){
      return document.getElementById('eway-payment-window');
    },
    cleanupPaymentElements(){
      // Remove existing script, button and payment window if are present
      const paymentWindow = this.isEWayWindowLoading();
      if(paymentWindow)
        paymentWindow.remove();
      const existingScript = document.getElementById('ewayButton');
      if(existingScript)
        existingScript.remove();
      const existingButton = document.getElementsByClassName('eway-button');
      if(existingButton.length > 0)
        existingButton[0].remove();
    },
    addEwayScript() {
      this.cleanupPaymentElements();

      const myScript = document.createElement('script');
      myScript.setAttribute(
        'src',
        'https://secure.ewaypayments.com/scripts/eCrypt.min.js'
      );
      myScript.setAttribute('id', 'ewayButton');
      myScript.setAttribute('class', 'eway-paynow-button');
      myScript.setAttribute(
        'data-publicapikey',
        process.env.VUE_APP_EWAY_PUBLIC_KEY
      );
      myScript.setAttribute(
        'data-resulturl',
        process.env.VUE_APP_APP_PROCESS_URL
      );
      myScript.setAttribute('data-amount', this.totalPaid * 100);
      myScript.setAttribute('data-currency', 'AUD');
      myScript.setAttribute('data-label', 'Pay now');
      myScript.setAttribute('data-invoiceref', this.tenantCode);
      this.setInvoiceDescription(myScript);

      // Append script
      this.$refs.eWayPlace.insertAdjacentElement('afterbegin', myScript);
    },
    setInvoiceDescription(ewayElement) {
      const invoiceDescription = `${this.rentUpToFormatted},${this.rentPaid},${this.bondPaid},${this.waterPaid},${this.maintenancePaid}`;
      ewayElement.setAttribute('data-invoicedescription', invoiceDescription);
    },
    calculateTotalPaid(isDefault) {
      if (isDefault) {
        this.totalPaid =
          Math.round(
            (parseFloat(this.water) +
              parseFloat(this.bond) +
              parseFloat(this.rent) +
              parseFloat(this.maintenance)) *
              100
          ) / 100;
      } else {
        this.totalPaid =
          Math.round(
            (parseFloat(this.waterPaid) +
              parseFloat(this.bondPaid) +
              parseFloat(this.rentPaid) +
              parseFloat(this.maintenancePaid)) *
              100
          ) / 100;
      };
      // Fix for the scenario when user clicked the payment button, but changing the values while it's loading
      if(this.isEWayWindowLoading())
        this.addEwayScript();
    },
    calculatePaidUpDate() {
      if (this.isAnotherAmount) {
        const dailyRent = parseFloat(this.weeklyRent) / 7;
        const amountDifference =
          parseFloat(this.rentPaid) - parseFloat(this.rent);
        let diffDays;
        if (amountDifference < 0) {
          diffDays = Math.floor(amountDifference / dailyRent) + 1;
        } else {
          diffDays = Math.floor(amountDifference / dailyRent);
        }

        this.rentUpToCalculated = addDays(this.rentUpTo, diffDays);
        this.rentUpToFormatted =
          this.rentUpToCalculated.toLocaleDateString('en-AU');
      } else {
        this.rentUpToCalculated = this.rentUpTo;
        this.rentUpToFormatted =
          this.rentUpToCalculated.toLocaleDateString('en-AU');
      }
    },
    storePaidDefaults(isDefault) {
      if (isDefault) {
        localStorage.paidWater = this.water;
        localStorage.paidBond = this.bond;
        localStorage.paidRent = this.rent;
        localStorage.paidMaintenance = this.maintenance;
      } else {
        localStorage.paidWater = this.waterPaid;
        localStorage.paidBond = this.bondPaid;
        localStorage.paidRent = this.rentPaid;
        localStorage.paidMaintenance = this.maintenancePaid;
      }
      localStorage.rentUpToCalculated =
        this.rentUpToCalculated.toLocaleDateString('en-AU');
      localStorage.payer = this.payerName;
    },
  },
  watch: {
    isAnotherAmount(val) {
      this.calculateTotalPaid(!val);
      this.storePaidDefaults(!val);
      this.calculatePaidUpDate();
      this.setInvoiceDescription(document.getElementById('ewayButton'));
    },
    payerName(val) {
      localStorage.payer = val;
      this.setInvoiceDescription(document.getElementById('ewayButton'));
    },
    totalPaid(val) {
      document
        .getElementById('ewayButton')
        .setAttribute('data-amount', Math.round(parseFloat(val) * 100));
      this.setInvoiceDescription(document.getElementById('ewayButton'));
      if (document.getElementsByClassName('eway-button')[0])
        document.getElementsByClassName(
          'eway-button'
        )[0].firstChild.innerHTML = `Pay now ($${this.totalPaid.toFixed(2)})`;
    },
    waterPaid(val) {
      if (val === '') this.waterPaid = 0;
      this.calculateTotalPaid(false);
      this.storePaidDefaults(false);
      this.setInvoiceDescription(document.getElementById('ewayButton'));
    },
    bondPaid(val) {
      if (val === '') this.bondPaid = 0;
      this.calculateTotalPaid(false);
      this.storePaidDefaults(false);
      this.setInvoiceDescription(document.getElementById('ewayButton'));
    },
    rentPaid(val) {
      if (val === '') this.rentPaid = 0;
      this.calculatePaidUpDate();
      this.calculateTotalPaid(false);
      this.storePaidDefaults(false);
      this.setInvoiceDescription(document.getElementById('ewayButton'));
    },
    maintenancePaid(val) {
      if (val === '') this.maintenancePaid = 0;
      this.calculateTotalPaid(false);
      this.storePaidDefaults(false);
      this.setInvoiceDescription(document.getElementById('ewayButton'));
    },
  },
};
</script>
